<template>
  <div class="document-request-point-ec">
    <Header :typeComponent="'point-exchange-confirm'" business/>
    <v-col>
      <v-row class="flex-fill">
        <BaseContentIntro>
          <template v-slot:title>
            <span class="color-title">{{ $t('pointExchangeConfirm.settingsTitle') }}</span>
          </template>
        </BaseContentIntro>
      </v-row>
    </v-col>

    <v-row class="d-flex justify-center align-center d-box-act mt-4 mb-9">
      <v-col class="d-flex justify-center align-center d-box-act">
        <span v-if="invalid">無効なURLです。</span>
        <span v-else-if="isConfirmReceiveOfPointExchange">ポイント交換受付確認が完了しました。このままページを閉じて下さい</span>
        <span v-else>ポイント交換受付確認をしています。少々お待ち下さい。</span>
      </v-col>
    </v-row>
    <Footer :typeComponent="'form'" business/>
  </div>
</template>

<script>
import BaseContentIntro from '@/components/common/BaseContentIntro'
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import { mapState } from 'vuex'

export default {
  mixins: [
  ],
  data () {
    return {
      id: '',
      code: '',
      invalid: false
    }
  },
  components: {
    BaseContentIntro,
    Footer,
    Header
  },
  mounted () {
    if (!this.invalid) {
      this.$store.dispatch('fetchPointExchangeConfirmDispatch', {
        payload: {
          id: +this.id,
          code: this.code
        },
        toast: this.$toast,
        apolloClient: this.$apollo
      })
    }
  },
  computed: {
    ...mapState({
      // Format [stageName] => state.[modulesName].[stageName],
      isConfirmReceiveOfPointExchange: state => state.pointExchangeConfirm.isConfirmReceiveOfPointExchange
    }),
    handlerClickStep () {
      // console.log(data)
      return {}
    }
  },
  created () {
    const query = this.$route.query
    if (!(query?.id && !!query?.id && query?.code && !!query?.code)) {
      // Redirect to page home
      this.invalid = true
      return
    }
    this.code = query.code
    this.id = query.id
  }
}
</script>

<style lang="scss">
</style>
